import React, { Component } from 'react';
import TabSwitch from '../../../../../components/tab-switch';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
SwiperCore.use([Pagination, Autoplay]);

import './index.less';

export default class SecondScreen extends Component<any , any> {
    constructor(props: any) {
        super(props);

        const state: any = {
            tabList: [
                {
                    value: '课程全',
                },
                {
                    value: '名师多',
                },
                {
                    value: '口碑佳',
                },
            ],
            active: 0,
            teacherList: [
                {
                    id: 1,
                    name: '潘庆华',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/VLJRV31J-T4IJ-5O9A-1723005827443-844VKXWBN1EZ.png',
                },
                {
                    id: 3,
                    name: '田珂',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/VF6LNK4A-IAWQ-EEOQ-1723005828992-8VOZ9WF469GP.png',
                },
                {
                    id: 10,
                    name: '杨淇',
                    img: "https://media.qianliaowang.com/qlLive/activity/image/V59ALBFB-OOE6-JI6E-1723005830454-FI3ZNWCE7QH4.png",
                },
                {
                    id: 11,
                    name: '杜杜',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/U9L28ATJ-X64O-SRCZ-1723005819670-BWDVBE77MBFV.png',
                },
                {
                    id: 12,
                    name: "耿春晖",
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/HRKQS3GD-IMR5-RV61-1723005821197-5XDFXQFF7KKV.png',
                },
                {
                    id: 13,
                    name: '李在峰',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/GCRVKI22-2LGA-V1OT-1723005824472-YPH1D33QLTDW.png',
                },
                // {
                //     id: 15,
                //     name: '杨舒',
                //     img: 'https://media.qianliaowang.com/qlLive/activity/image/742EV9BQ-5CBV-22O7-1723005831921-45RM21IY5CBC.png',
                // },
                {
                    id: 15,
                    name: '袁文昊',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/2VLC184K-GQNT-GLXE-1723005833719-1KDA81M26A1O.png',
                },
                {
                    id: 15,
                    name: '迷罗',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/LKBXINK1-I8CK-L61P-1723005825946-VZ59WQ34S1RY.png',
                },
                {
                    id: 16,
                    name: '华一',
                    img: 'https://media.qianliaowang.com/qlLive/activity/image/6IV7UUB6-VZIU-HGPM-1723005822614-3B9TY1DHBQ7V.png',
                },
            ],
            studentList: [
                [
                    {   
                        id: 11,
                        img: 'https://img.qlchat.com/qlLive/activity/image/YYIE8K58-XOJV-RVSV-1709174203424-QSE6RQNNDMZQ.png',
                        title: '兴趣岛手机摄影训练营学员',
                        name: '简易·70后硕士宝妈',
                        desc: '2022年，我在兴趣岛学习手机摄影，重拾年轻时的热爱。我的6幅摄影作品成功入展了<span style="font-weight:bold;">韩国仁川国际海洋摄影节</span>，1幅作品<span style="font-weight:bold;">斩获魅力中国摄影大赛二等奖</span>。我现在还成为了视觉中国的签约供稿师，作品成功变现。愿我们都能从自己的“热爱”里得到力量和快乐！',
                    },
                    {
                        id: 12,
                        img: 'https://img.qlchat.com/qlLive/activity/image/2QRMX95R-71LM-MI3T-1709174245438-PFOAOINEBN5Y.png',
                        title: '兴趣岛手机摄影训练营学员',
                        name: '刘浪汉·55岁个体户',
                        desc: '作为一名摄影爱好者，通过在兴趣岛手机摄影训练营的学习，我的摄影水平更上一层楼。不仅一举斩获兴趣岛<span style="font-weight:bold;">手机摄影大赛城市赛道亚军</span>，还成为<span style="font-weight:bold;">香港中国旅游出版社高级签约摄影师</span>，多张作品荣获<span style="font-weight:bold;">财富人生杯北美新视觉艺术大赛奖项</span>。只要心中有梦，并一直在路上，总有一天会实现。',
                    },
                ],
                [
                    {   
                        id: 21,
                        img: 'https://img.qlchat.com/qlLive/activity/image/8IL62OKN-4UYT-QIYG-1709174237960-KRI7HIBLTOKE.png',
                        title: '兴趣岛吃瘦训练营学员',
                        name: '张梅·医务工作者',
                        desc: '2022年，我遇到了兴趣岛吃瘦训练营，成功<span style="font-weight:bold;">减脂二十多斤</span>，而且身体的代谢能力更好了，高血压、高血脂也得到了改善。由于外形的优化，自己也逐渐找回了自信。<span style="font-weight:bold;">健康吃瘦，夺回身体的主动权！</span>',
                    },
                    {
                        id: 22,
                        img: 'https://img.qlchat.com/qlLive/activity/image/RWZTXMVK-VB8K-KMTW-1709174231280-LS8TMW3SZDPQ.png',
                        title: '兴趣岛吃瘦训练营学员',
                        name: '林嘉·退休人员',
                        desc: '一次偶然的机会，我报名了兴趣岛吃瘦训练营。经过在吃瘦营和断食营两个多月的学习，我不仅<span style="font-weight:bold;">成功瘦身10公斤</span>，身体的各项功能也恢复正常了。人生没有“退休”，换一个赛道成长，为自己的健康保驾护航。',
                    },
                ],
                [
                    {   
                        id: 31,
                        img: 'https://img.qlchat.com/qlLive/activity/image/NVB53C4M-8WB3-BGUM-1709174227494-ASAV62HERREY.png',
                        title: '兴趣岛穿搭营学员',
                        name: '静姐·58岁退休人员',
                        desc: '在刚开始学习课程时有点自卑，觉得自己岁数太大 ，学习能力比其他同学要低，但是在经过不断的鼓励下，她慢慢打开自己，与同学互动并且鼓励其他同学一起学习，起到带头学习的榜样作用。这样的改变不止在穿着打扮上体现 ，更在生活中的方方面面也<span style="font-weight:bold;">更加自信 ，乐于交友</span>。',
                    },
                    {
                        id: 32,
                        img: 'https://img.qlchat.com/qlLive/activity/image/8K5HIVUX-MQ4S-ZW9Y-1709174223438-F5MKYTRSLKB4.png',
                        title: '兴趣岛穿搭营学员',
                        name: '章女士·32岁宝妈',
                        desc: '章女士在课后积极地改变自己，整理自己的衣橱，找到自己的穿衣风格，章女士说：“<span style="font-weight:bold;">改变了自己穿搭后</span>，无论到什么地方，总是会收到别人的夸奖，这也让我有了更多的信心，我真的特别感谢杜杜老师的课程，让我找回来自我。”',
                    },
                ],
                [
                    {   
                        id: 41,
                        img: 'https://img.qlchat.com/qlLive/activity/image/UTD2OCQE-W3AH-RUHO-1709174219943-QNHK7DQ5R7ZA.png',
                        title: '兴趣岛瑜伽训练营学员',
                        name: '羽墨·70后二孩宝妈',
                        desc: '2022年，我报名了兴趣岛瑜伽训练营，一个月时间，我练出了肉眼可见的翘臀和马甲线，<span style="font-weight:bold;">皮肤也变得紧致红润</span>。由于我孕期胎位高，生完孩子后肋骨外翻得很厉害，现在已经基本调整回来了，身体素质也比之前好。健康与美丽掌控在自己手中的感觉，真的很好！',
                    },
                    {   
                        id: 42,
                        img: 'https://img.qlchat.com/qlLive/activity/image/5LKQRKOE-5YUV-IRRR-1709174210952-8T827Q7VXGSG.png',
                        title: '兴趣岛瑜伽训练营学员',
                        name: '王潇·职场白领',
                        desc: '2022年，我在兴趣岛报名了瑜伽课程。坚持了18天的课程后，我肚子上的赘肉明显变少，<span style="font-weight:bold;">骨盆前倾也逐渐回正</span>，腰痛问题减轻，皮肤也变得更加细腻有弹性，变得更加快乐自信，慢慢回到了年轻时的状态。愿每一位女性都能在学习中发现焕然一新的自我，迎来积极快乐的生活。',
                    },
                ],
                [
                    {   
                        id: 51,
                        img: 'https://img.qlchat.com/qlLive/activity/image/DTMQ5F5A-SDGQ-BJLO-1709174206879-TPV1G42DOJO6.png',
                        title: '兴趣岛中医气血调理训练营',
                        name: '萱萱·53岁',
                        desc: '在兴趣岛迷罗老师的课程上，我深刻认识到<span style="font-weight:bold;">不止养生、更要养德、这样才能滋养生命</span>。养生就是滋养我们的生命力！掌握中医养生的方法就是掌握健康活力的秘诀。知识的魅力不仅仅是滋养自己，更是在掌握和应用于生活中，让我们自身健康的同时，通过我们身体力行的传递，帮助更多人获得健康。',
                    },
                ],
            ]
        }
        this.state = state;
    }
    changeTab = (active: number) => {
        this.setState({ active });
        const tabActiveDom: any = document.querySelector('.tab-active');
        tabActiveDom.style.left = `${active * (1 / 3) * 100}%`;
    }
    public render(): any {
        const { tabList, active, teacherList, studentList } = this.state;
        const teacherListFlag: boolean = teacherList.length > 1;
        const studentListFlag: boolean = studentList.length > 1;
        return (
            <div className='second-screen'>
                <div className="second-screen-tab-box">
                    <TabSwitch className="second-screen-tab" changeTab={this.changeTab} list={tabList} active={active} isTabActive />
                </div>

                {
                    active == 0 ? (
                        <>
                            <div className="tab-1 fadeInUp">
                                <img src="https://media.qianliaowang.com/qlLive/activity/image/8GK3L64F-6AWR-NM8K-1731047940951-UBQCTTJKM18V.png?x-oss-process=image/format,webp" />
                            </div>
                            <div className="second-screen-empty"></div>
                        </>
                    ) : null
                }
                {
                    active == 1 ? (
                        <div className="tab-2">
                            <Swiper
                                observer={true}
                                observeParents={true}
                                loop={teacherListFlag}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={'auto'}
                                centeredSlides={true}
                                // pagination={{ clickable: true }}
                                className="famous-teacher-box-swiper">
                                {teacherList.map((item: any, index: number): any => (
                                    <SwiperSlide key={index}>
                                        <div className="famous-teacher-box-swiper-slide">
                                            <div className="advantages-img">
                                                <img src={item.img} />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="tab-2-guideline fadeInUp">
                                <div className="guideline">
                                    <img src="https://img.qlchat.com/qlLive/activity/image/3ICOXNRG-HGBJ-QH4J-1709172302459-1WNQ3KHIOE5Z.png" />
                                    <p>每位课程老师必须从业10年以上</p>
                                </div>
                                <div className="guideline">
                                    <img src="https://img.qlchat.com/qlLive/activity/image/3ICOXNRG-HGBJ-QH4J-1709172302459-1WNQ3KHIOE5Z.png" />
                                    <p>必须具备行业顶尖资质</p>
                                </div>
                                <div className="guideline">
                                    <img src="https://img.qlchat.com/qlLive/activity/image/3ICOXNRG-HGBJ-QH4J-1709172302459-1WNQ3KHIOE5Z.png" />
                                    <p>有1000+的课程效果口碑</p>
                                </div>
                                <div className="guideline">
                                    <img src="https://img.qlchat.com/qlLive/activity/image/3ICOXNRG-HGBJ-QH4J-1709172302459-1WNQ3KHIOE5Z.png" />
                                    <p>有多位跟随学习5年以上的学生</p>
                                </div>
                                <div className="guideline">
                                    <img src="https://img.qlchat.com/qlLive/activity/image/3ICOXNRG-HGBJ-QH4J-1709172302459-1WNQ3KHIOE5Z.png" />
                                    <p>具备普世教育和尊爱学员的情怀</p>
                                </div>
                            </div>
                            <div className="second-screen-empty"></div>
                        </div>
                    ) : null
                }
                {
                    active == 2 ? (
                        <div className="tab-3 fadeInUp">
                            <Swiper
                                observer={true}
                                observeParents={true}
                                loop={studentListFlag}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                slidesPerView={'auto'}
                                centeredSlides={true}
                                pagination={{ clickable: true }}
                                className="famous-student-box-swiper">
                                {studentList.map((item: any, index: number): any => (
                                    <SwiperSlide key={index}>
                                        {
                                            item.map((studentItem: any, studentIndex: number): any => (
                                                <div key={studentIndex} className={`student-card ${studentIndex == item.length - 1 ? 'last' : ''}`}>
                                                    <div className="student-card-contain">
                                                        <img src={studentItem.img} />
                                                        <div className="student-card-info">
                                                            <p className="title">{studentItem.title}</p>
                                                            <span className="name">{studentItem.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="student-card-desc" dangerouslySetInnerHTML={{ __html: studentItem.desc }}></div>
                                                </div>
                                            ))
                                        }
                                        <div className="second-screen-empty"></div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}