import React, { Component } from 'react';
import './style.less';

interface TabSwitchProps {
	changeTab: (v: number) => void,
	className?: string,
	isShowResult?: boolean,
	list: Array<object>,
	active?: 0,
	isTabActive?: boolean,
}

class TabSwitch extends Component<TabSwitchProps> {
	public state: any = {
		active: 0,
	}
	changeTab = (active) => {
		this.setState({
			active,
		});
		this.props.changeTab?.(active);
	}
	public async componentDidMount(): Promise<any> {
		this.setState({
			active: this.props.active || 0,
		});
	}
	// eslint-disable-next-line react/no-deprecated
	public componentWillReceiveProps(nextProps:any): void {
		if (this.props.active !== nextProps.active) {
			this.setState({
				active: nextProps.active,
			});
		}
	}
	/**
	 * render
	 *
	 * @returns {*}
	 */
	public render(): any {
		return !!this.props.list && (
			<div className={`tab-switch ${this.props.className || ''}`}>
				{
					this.props.list.map((item, index) => (
						<div key={index} className={`tab-switch-item ${this.state.active == index ? 'active' : ''}`} onClick={() => { this.changeTab(index); }}>{(item as any).value}</div>
					))
				}
				{ this.props.isTabActive ? <div className="tab-active"></div> : null }
			</div>
		);
	}
}

export default TabSwitch;
